import { UarClassificationReviewResource } from './ClassificationReview';
import { UarClassificationExceptionResource } from './ExceptionReview';

import { AthenaTriageFilterField } from './ManualReviewTriage';

import { EvidenceRequestAttachment } from '../Evidence';

export interface IReview {
    id: string;
    name: string;
}

export interface UarResourceBase {
    AssignToAccountGuid: string;
    AssignToDisplayName: string;
    IsManual: boolean;
    IsManuallyLinkedResource: boolean;
    ResourceIdentifier: string;
    ResourcePath: string;
    IsInScope: boolean;
    IsProdEnvironment: boolean;
    Justification: string | null;
    ExceptionRejectionJustification: string | null;
    ResourceGuid: string;
    ResourceName: string;
    ResourceTypeGuid: string;
    ResourceTypeName: string;
    ReviewedByAccountGuid: string | null;
    ReviewedByDisplayName: string;
    ServiceName: string;
    ServiceResourceClassificationReviewGuid: string;
    ServiceTreeId: string;
    TenantGuid: string;
    TenantName: string;
    UserAccessReviewGuid: string;
    ExceptionReviewedByAccountGuid: string;
    ExceptionReviewedByDisplayName: string;
    ExceptionRequestedByAccountGuid : string;
    ExceptionRequestedByDisplayName : string;
    AccessControlLists: AccessControl[] | null,
    Attachments: EvidenceRequestAttachment[],
    RequestedByAccountGuid: string;
    RequestedByDisplayName: string | null;
    CanReview?: boolean;
    CanDelegate?: boolean;
}

export interface AccessControl {
    ObjectId: string,
    Name: string,
    Permissions: string[]
}

export interface UarReviewServiceBase {
    ServiceTreeId: string;
    ServiceName: string;
    UserAccessReviewGuid: string;
}

export type UarResource = UarClassificationReviewResource | UarClassificationExceptionResource;

export enum ClassificationReviewServiceState {
    NoResources = 'NoResources',
    InProgress = 'InProgress',
    Completed = 'Completed',
}

export enum ClassificationReviewResourceState {
    InReview = 'InReview',
    Reviewed = 'Reviewed',
    // This is FE only status
    NewResource = 'NewResource',
}

export enum ClassificationReviewResourceExceptionState {
    InReview = 'InReview',
    Approved = 'Approved',
    Rejected = 'Rejected',
    // This is FE only status
    ExceptionRequested = 'ExceptionRequested',
}

// TODO [Remove circular dependencies]
// import from sources/dev/TrustPortal/ClientApp/src/components/StyledLabel/type.ts
export enum LabelColor {
    CyanBlue = 'cyanblue',
    GreenCyan = 'greencyan',
    Green = 'green',
    Red = 'red',
    Orange = 'orange',
    PinkRed = 'pinkred',
    Gray = 'gray'
}

export interface UarResourceStateProps {
    color: LabelColor;
    text: string;
    ring: boolean;
}

export interface UarPermissions {
    CanCreateIterations: boolean;
    CanEditIterations: boolean;
    CanAttestServices: string[];
    CanAddResourceToServices: string[];
    CanViewIterationStages: string[];
    MustUseNarrowingFilters: boolean;
}

export interface UarIterationFilter {
    UserAccessReviewGuid?: string;
}

export enum UARReviewType {
    ClassificationReview,
    ClassificationExceptionReview,
    RevocationReview,
    RevocationExceptionReview,
    ManagerReview,
    ManualReview
}

export enum AttestationType {
    ClassificationReview = 'ClassificationReview',
    ManualReview = 'ManualReview'
}

export enum ResourceCreationMethod {
    Manual = 'Manual',
    Automatic = 'Automatic'
}

export enum TenantType {
    Microsoft = 'Microsoft',
    None = 'None',
    Other = 'Other'
}

export enum ManualResourceType {
    AzureSubscription = 'Azure Subscription',
    IcMTenant = 'IcM Tenant',
    MdmAccount = 'Mdm Account',
    MdsAccount = 'Mds Account',
    SQLServer = 'SQL Server 2016',
    AzureServiceBus = 'Azure Service Bus',
    Other = 'Other'
}

export enum ResourceType {
  AzureSubscription = 'Azure Subscription',
  IcMTenant = 'IcM Tenant',
  MdmAccount = 'Mdm Account',
  MdsAccount = 'Mds Account',
  SQLServer = 'SQL Server 2016',
  AzureServiceBus = 'Azure Service Bus',
  Other = 'Other'
}

export interface UARUIString {
    Id: number,
    Context: string,
    Category: string,
    Value: string
}

export interface GetTriageRequestParams {
    filters: Record<string, any>;
    filterFields: AthenaTriageFilterField[];
  }
