export interface UarGetManualReviewTriageActionItemsRequest {
    IterationGuid: string;
    filters: Record<string, any>;
}

export interface AthenaTriageFilterField {
    FieldName: string;
    DisplayName: string;
    FilterType: AthenaTriageFilterType;
    FilterValueType: AthenaTriageFilterValueType;
    Values: AthenaTriageFilterValue[];
}

export interface AthenaTriageFilterValue {
    DisplayName: string;
    Value: string;
    Connections?: AthenaTriageFilterConnection[];
}

export interface AthenaTriageFilterConnection {
    FieldName: string;
    Value: any
}

export enum AthenaTriageFilterType {
    Static = 'Static',
    Dynamic = 'Dynamic',
    UserSearch = 'UserSearch',
    DynamicAndConnected = 'DynamicAndConnected'
}

export enum AthenaTriageFilterValueType {
    String = 'String',
    Guid = 'Guid',
    Integer = 'Integer',
}

export enum UarEvidenceInspectionDecision {
    Approve = 'Approve',
    Reject = 'Reject'
}

export interface UarEvidenceInspectionDecisionItem {
    manualReviewGuid: string,
    decision: UarEvidenceInspectionDecision,
    setJustification: string,
}

export interface UarEvidenceInspectionDecisionPayload {
    userAccessReviewGuid: string,
    setItems: UarEvidenceInspectionDecisionItem[]
}
